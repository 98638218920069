<template>
  <div>
    <v-toolbar dense flat>
      <v-app-bar-nav-icon to="/product/home" small>
        <v-icon>home</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Product</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text color="teal" dark to="/product/list">
        Product List
      </v-btn>

      <v-btn text color="teal" dark to="/product/category/list">Category</v-btn>
      <v-btn text color="teal" dark to="/product/department/list">Department</v-btn>
      <v-btn text color="teal" dark to="/product/brand/list">Brand</v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "product",
  data() {
    return {};
  },
  created() {}
};
</script>
