<template>
  <v-container fluid grid-list-lg>
    <v-row v-if="!loading" align="center" justify="space-between">
      <v-col class="shrink">
        <v-btn @click="$router.go(-1)" text icon color="primary">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <h1>{{ Product.name }}</h1>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <div class="text-right">
          <span>Stock:</span>
          <span class="font-weight-bold">{{ Product.balance }}</span>
        </div>
        <div class="text-right">
          <small>ID:{{ Product.localId }}</small>
        </div>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row class="mt-2 pb-0">
      <v-col>
        <v-card outlined flat>
          <v-card class="pa-2" dark flat>
            <div>Total Count</div>
          </v-card>
          <v-card-text>
            <div class="text-center">
              <div class="text-h3">{{ countedStock.length }}</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined flat>
          <v-card class="pa-2" color="green" dark flat>
            <div>Found</div>
          </v-card>
          <v-card-text>
            <div class="text-center">
              <div class="text-h3">{{ foundCount }}</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined flat>
          <v-card class="pa-2" color="red" dark flat>
            <v-layout row wrap align-center>
              <v-flex>
                <span>New</span>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-btn small rounded outlined @click="saveInPurchase"
                  >Add In Purchase</v-btn
                >
              </v-flex>
            </v-layout>
          </v-card>
          <v-card-text>
            <div class="text-center">
              <div class="text-h3">{{ newFound }}</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat outlined>
          <v-card class="pa-2" dark flat>
            <div>Not Counted</div>
          </v-card>
          <v-card-text>
            <div class="text-center">
              <div class="text-h3">{{ stocks.length }}</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card flat outlined>
          <v-card flat class="pa-3 ma-2">
            <v-text-field
              v-model="search"
              outlined
              ref="serialNumber"
              prepend-inner-icon="search"
              rounded
              :loading="checkingNumber"
              :disabled="checkingNumber"
              dense
              @keypress.enter="searchItem"
              label="Scan Serial Number"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-card>
          <v-divider></v-divider>
          <v-card-text>
            <v-list class="inventory-view-count">
              <template v-for="(stock, index) in countedStock">
                <v-list-item :key="stock.id">
                  <v-list-item-icon>
                    <v-btn
                      @click="deleteRow(stock, index)"
                      text
                      icon
                      small
                      color="primary"
                    >
                      <v-icon color="red">delete</v-icon>
                    </v-btn>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="stock.imeiNumber"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon v-if="stock.id" color="green"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                    <v-icon v-else color="red">mdi-minus-circle</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider
                  :key="index"
                  v-if="index + 1 < stocks.length"
                ></v-divider>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card flat outlined>
          <v-card dark class="pa-3 ma-2">
            <v-layout row wrap align-center>
              <v-flex>
                <span>Item In Stock</span>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-btn
                  small
                  rounded
                  outlined
                  :disabled="markItAllSoldLoading"
                  @click="markItAllSold"
                  >{{ stocks.length }} Mark it sold</v-btn
                >
              </v-flex>
            </v-layout>
          </v-card>
          <v-card-text>
            <v-list class="inventory-view-container">
              <template v-for="(stock, index) in stocks">
                <v-list-item :key="stock.id">
                  <v-list-item-icon>
                    <v-btn
                      :loading="markItSoldLoading"
                      @click="setOpenSerialSearch(stock)"
                      text
                      icon
                      small
                      color="primary"
                    >
                      <v-icon color="green">mdi-feature-search-outline</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="stock.imeiNumber"
                    ></v-list-item-title>
                    {{ stock.loading }}
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn
                      :loading="markItSoldLoading"
                      @click="markItSold(stock, index)"
                      text
                      icon
                      small
                      color="primary"
                    >
                      <v-icon color="red">delete</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider
                  :key="index"
                  v-if="index + 1 < stocks.length"
                ></v-divider>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import productService from "../service";
import purchaseService from "@/modules/Inventory/Purchase/service.js";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "product-list",
  data() {
    return {
      checkingNumber: false,
      Product: null,
      singleExpand: true,
      hideZeroProduct: false,
      createDialog: false,
      editDialog: false,
      products: [],
      stocks: [],
      countedStock: [],
      search: "",
      loading: true,
      page: 1,
      markItSoldLoading: false,
      markItAllSoldLoading: false,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  async created() {
    // this.getList();
    // console.log("router", this.$route.params.id);
    if (!this.$route.params && !this.$route.params.id) {
      this.$router.push({ path: `/product` });
    } else {
      await this.loadProduct(this.$route.params.id);
      await this.getStocks();
    }

    // if (this.$route.query && this.$route.query.search) {
    //   this.search = this.$route.query.search;
    // }
    document.title = "smplsale - Inventory Adj";
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
    foundCount() {
      let total = 0;
      this.countedStock.map((row) => {
        if (row.id) {
          total += 1;
        }
      });

      return total;
    },
    newFound() {
      let total = 0;
      this.countedStock.map((row) => {
        if (!row.id) {
          total += 1;
        }
      });

      return total;
    },
  },
  methods: {
    ...mapActions("layout", ["setOpenSerialSearch", "setOpenRestockSerial"]),
    saveInPurchase() {
      let passData = {
        Product: this.Product,
        ImeiNumber: [],
      };
      if (this.newFound < 1) return false;
      this.$swal({
        title: "Are you sure?",
        text: `You want to move ${this.newFound} items to new purchase`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.countedStock.map((row) => {
            if (!row.id) {
              passData.ImeiNumber.push(row.imeiNumber);
            }
          });
          this.$router.push({ name: "purchase-create", params: passData });
        }
      });
    },
    markItAllSold() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to mark ${this.stocks.length} items sold!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          this.markItSoldLoading = true;
          this.markItAllSoldLoading = true;
          await this.stocks.reduce(async (previousPromise, row) => {
            await previousPromise;
            return this.sold(row);
          }, Promise.resolve());

          // this.getStocks()
          this.markItSoldLoading = false;
          this.markItAllSoldLoading = false;
          this.$swal("All Set", "All the time was mark it sold", "success");
        }
      });
    },
    async sold(row) {
      await purchaseService.markItSold(row).then((resp) => {
        // console.log("resp", resp);
        let i = this.stocks.indexOf((stock) => {
          return stock.imeiNumber === row.imeiNumber;
        });
        this.stocks.shift(i, 1);
      });
    },
    async markItSold(obj, index) {
      this.markItSoldLoading = true;
      await purchaseService
        .markItSold(obj)
        .then(() => {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "success",
              title: `Item was Mark it Sold`,
            });
          this.markItSoldLoading = false;
          this.stocks.splice(index, 1);
        })
        .catch(() => {
          this.markItSoldLoading = false;
        });
    },
    deleteRow(stock, index) {
      if (stock.id) {
        // console.log("stock here");
        this.countedStock.splice(index, 1);
        this.stocks.unshift(stock);
      } else {
        this.countedStock.splice(index, 1);
      }
    },
    loadProduct(id) {
      return productService.getById(id).then((result) => {
        // console.log("produ", result);
        this.Product = result.data;
        this.loading = false;
      });
    },
    getStocks() {
      this.loadingStock = true;
      const where = {
        status: "available",
        ProductId: this.Product.id,
      };
      return purchaseService.getSerialVault(where).then(async (response) => {
        // console.log("getSerialVault", response);
        this.loading = false;
        this.stocks = response.data;
        // this.stocks.map(stock => {
        //   stock.loading = false
        // })
        this.loadingStock = false;
        this.Product.balance = this.stocks.length;
        // console.log("product ", this.Product);
        await productService
          .update(this.Product.id, this.Product)
          .then((response) => {
            // console.log("response", response);
          });
        return response;
      });
    },
    emtpyBar() {
      this.checkingNumber = false;
      this.search = "";
      let vs = this.$refs;
      setTimeout(function () {
        if (vs && vs.serialNumber) vs.serialNumber.focus();
      }, 100);
    },
    async searchItem() {
      this.checkingNumber = true;
      let productSearch = await purchaseService.searchLog({
        imeiNumber: this.search,
      });
      // console.log("productSearch", productSearch);
      if (
        productSearch.data.serialVault &&
        productSearch.data.serialVault.ProductId !== this.Product.id
      ) {
        // console.log("other product");
        this.$swal(
          "Exiting Product",
          `${this.search} Is beloge to ${productSearch.data.serialVault.Product.name}`,
          "warning"
        ).then(() => {
          this.emtpyBar();
        });

        return false;
      }
      // setTimeout(function() {
      //   if (this.$refs && this.$refs.serialNumber) this.$refs.serialNumber.focus();
      // }, 300);

      let foundInCountList = this.countedStock.findIndex((stock) => {
        return stock.imeiNumber.toUpperCase() === this.search.toUpperCase();
      });

      // // console.log('foundInCountList', foundInCountList)
      if (foundInCountList == -1) {
        let found = this.stocks.findIndex((stock) => {
          return stock.imeiNumber.toUpperCase() === this.search.toUpperCase();
        });

        if (found !== -1) {
          this.countedStock.unshift(this.stocks[found]);
          this.stocks.splice(found, 1);
          // console.log("found");
        } else {
          // console.log("not found");
          this.countedStock.unshift({ imeiNumber: this.search });
        }
      } else {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: `${this.search} is already counded.`,
          });
        this.emtpyBar();
      }
      this.emtpyBar();
    },
  },
};
</script>
<style >
.inventory-view-container {
  height: calc(100vh - 492px);
  overflow: auto;
  width: 100%;
}

.inventory-view-count {
  height: calc(100vh - 507px);
  overflow: auto;
  width: 100%;
}
</style>
