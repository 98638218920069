<template>
  <v-container fluid grid-list-lg>
    <Create :createDialog="createDialog" @close="createDialog = !createDialog" @submit="getList"></Create>
    <Edit :editDialog="editDialog" @close="editDialog = !editDialog" @submit="getList" :id="id"></Edit>
    <v-card :loading="loading" outlined>
      <!-- <v-toolbar dense flat :color="color">
        <v-app-bar-nav-icon v-if="showBackButton" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <slot></slot>
        <v-btn v-if="access" @click="$emit('add')" outlined rounded raised small>
          <v-icon small>add</v-icon> New
        </v-btn>
      </v-toolbar> -->

      <!-- <v-btn color="success" @click="fix()">Fix Detail</v-btn> -->

      <ListTitle :access="checkRightStatus(1)" :title="$t('pageHeader.product')" @add="add"
        :color="$style.purchase.titlebar">
        <v-btn class="mr-2" v-on="downloadedProductCount < 1 ? on : undefined" :disabled="downloadedProductCount < 1"
          :icon="$vuetify.breakpoint.smAndDown" outlined :rounded="!$vuetify.breakpoint.smAndDown"
          :loading="downloadLoading" small>
          <download-excel :fetch="fetchProductsForDownload" worksheet="Product List" :stringifyLongNum="true" type="xls"
            :fields="fields" name="product">
            <span v-if="!$vuetify.breakpoint.smAndDown">Download</span>
            <v-icon small v-else>mdi-download</v-icon>
          </download-excel>
        </v-btn>
      </ListTitle>


      <v-toolbar dense elevation="0">
        <v-text-field v-model="search" outlined prepend-inner-icon="search" autofocus dense label="Search" single-line
          style="min-width: 250px;" hide-details clearable></v-text-field>

        <!-- <v-overflow-btn :items="dropdown_font" label="Select font" hide-details class="pa-0"></v-overflow-btn> -->

        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-divider vertical class="mx-1"></v-divider>



          <v-overflow-btn :items="departmentList" @change="getList()" return-object item-text="name" clearable editable
            outlined dense label="Department" v-model="selectedDepartment" hide-details class="pa-0"
            overflow></v-overflow-btn>

          <v-divider vertical class="mx-1"></v-divider>

          <v-overflow-btn :items="brandList" @change="getList()" clearable editable return-object item-text="name"
            outlined dense label="Brand" v-model="selectedBrand" hide-details class="pa-0" overflow></v-overflow-btn>

          <v-divider vertical class="mx-1"></v-divider>

          <v-overflow-btn :items="categoryList" @change="getList()" clearable editable return-object item-text="name"
            outlined dense label="Category" v-model="selectedCategory" hide-details class="pa-0"
            overflow></v-overflow-btn>

          <v-spacer></v-spacer>

          <v-btn-toggle v-model="openNewTab" color="deep-purple accent-3" dense group multiple>
            <v-btn :value="1" text>
              <v-icon>mdi mdi-tab</v-icon>
            </v-btn>



          </v-btn-toggle>


        </template>
        <!-- <div class="mx-4"></div> -->

        <v-btn-toggle v-model="stockFilter" color="deep-purple accent-3" dense group>


          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :value="1" text v-bind="attrs" v-on="on">
                <v-icon>mdi mdi-numeric-0-box-outline</v-icon>
              </v-btn>
            </template>
            <span>Hide 0 Stock Products</span>
          </v-tooltip>

        </v-btn-toggle>
      </v-toolbar>
      <v-divider></v-divider>

      

      <v-card-text>

        

        <v-data-table :fixed-header="true" :headers="headers" :items="products" :loading="loading" :sort-by="sortBy"
          :page.sync="page" :single-expand="singleExpand" :expanded.sync="expanded" show-expand
          :items-per-page="itemsPerPage">
          <template v-slot:item="{ item, index, isExpanded, expand }">
            <tr class="pointer" :class="{ isParent: item.isParent, hightlightRed: costGreater(item) }">
              
              <td class="text-left">
                <v-avatar v-if="!item.fix && !item.isParent" size="5" color="red" />
                <div v-if="item.trackSerialNumber">
                  <v-btn @click="expand(true)" icon v-if="!isExpanded">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                  <v-btn @click="expand(false)" icon v-if="isExpanded">
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </div>
                <div v-else-if="item.isParent">
                  <v-btn @click="expand(true)" icon v-if="!isExpanded">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                  <v-btn @click="expand(false)" icon v-if="isExpanded">
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </div>

              </td>
              <td>
                <v-tooltip bottom v-if="item.ChildProductId">
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on" class="mr-1">mdi-arrow-right-bottom</v-icon>
                    <span v-html="$highlight(item.localId, search)">{{ item.localId
                      }}</span>
                  </template>
                  <span>Child Product </span>
                </v-tooltip>

                <span v-else v-html="$highlight(item.localId, search)">{{ item.localId
                  }}</span>
              </td>
              <td class="text-left">

                <div>
                  <!-- <v-btn color="success" @click="updateBalanceProduct(item)">Fix Detail</v-btn> {{ item.fix }} -->
                  <div>
                    <span class="mr-2" v-html="$highlight(item.name, search)
                      "> {{ item.name }}</span>
                    <v-icon small v-if="!item.remoteOrder" color="orange">mdi mdi-web-cancel</v-icon>

                    <span v-if="currentBusiness.metadata && currentBusiness.metadata.outOfStock">
                      <v-chip v-if="item.balance <= 0" small color="pink" label text-color="white">
                        Out of Stock
                      </v-chip>
                    </span>

                  </div>
                  <div v-if="item.upc" class="caption">
                    UPC: {{ item.upc }}
                  </div>
                  <div v-if="item.Brand" class="caption">
                    Brand: {{ item.Brand.name }}
                  </div>
                  <div v-if="item.note && currentBusiness.metadata && currentBusiness.metadata.productDescription
                  " class="caption">
                    {{ item.note }}
                  </div>
                </div>
              </td>
              <td class="text-left">
                <span class="mr-2" v-if="item.Department" v-html="$highlight(item.Department.name, search)
                  ">{{ item.Department.name }}</span>
              </td>
              <td @click="updateBalanceProduct(item)" class="text-center">
                <div v-if="item.isParent">
                  <v-chip v-if="item.balance <= 0" small color="green lighten-4" label text-color="green darken-4">
                    Parent
                  </v-chip>
                </div>
                <div v-else-if="item.trackInventory">{{ item.balance }}</div>
                <div v-else> -- </div>

              </td>

              <td class="text-right">
                <div v-if="item.dynamicPrice === item.price" class="red--text">
                  <div v-if="item.isParent">--</div>
                  <div v-else>{{ item.dynamicPrice | currency }} *</div>
                </div>
                <div v-else>
                  <div v-if="item.isParent">--</div>
                  <div>{{ item.price | currency }} <span
                      v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">/
                      {{
                        item.unitCountInBox }} =
                      {{
                        item.unitPrice | currency }}</span>

                  </div>


                </div>
              </td>
              
              <td class="text-right" v-if="currentBusiness.metadata && currentBusiness.metadata.showAvgCostOnProduct">
                <div v-if="item.isParent">--</div>
                <div v-else>{{ item.avgCost | currency }}</div>
              </td>
              <td class="text-right" v-else>
                <div v-if="item.isParent">--</div>
                <div v-else>{{ item.cost | currency }}</div>
              </td>


              <td class="text-left">
                <v-chip :color="item.trackSerialNumber
                  ? 'light-blue darken-1'
                  : item.trackInventory ? 'teal darken-1' : 'yellow darken-4'
                  " text-color="white">
                  <v-avatar left class="light-blue darken-2" v-if="item.trackSerialNumber">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">mdi-barcode-scan</v-icon>
                      </template>
                      <span>Track with IMEI number</span>
                    </v-tooltip>
                    <!-- <v-icon small>mdi-barcode-scan</v-icon> -->
                  </v-avatar>
                  <v-avatar left v-else-if="item.trackInventory" class="teal darken-3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">mdi-package-variant-closed</v-icon>
                      </template>
                      <span>Track inventory on this product</span>
                    </v-tooltip>
                  </v-avatar>
                  <v-avatar left v-else class="yellow darken-3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">mdi-room-service-outline</v-icon>
                      </template>
                      <span>Track inventory on this product</span>
                    </v-tooltip>
                  </v-avatar>
                  <span v-html="$highlight(item.Category.name, search)">
                    {{ item.Category.name }}
                  </span>
                </v-chip>
              </td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item v-if="checkRightStatus(17)" @click="editProduct(item)">
                      <v-list-item-title>{{
                        $t("buttons.edit")
                        }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="checkRightStatus(9)" @click="deleteProduct(item)">
                      <v-list-item-title>{{
                        $t("buttons.delete")
                        }}</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="!item.trackSerialNumber"></v-divider>
                    <v-list-item @click="viewAvgCost(item)">
                      <v-list-item-title>View Avg Cost</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="item.trackSerialNumber"></v-divider>
                    <v-list-item v-if="item.trackSerialNumber" @click="inventoryAdj(item)">
                      <v-list-item-title>Inventory Count</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="item.trackSerialNumber && !item.isParent"></v-divider>
                    <v-list-item v-if="!item.isParent" @click="openHistory(item)">
                      <v-list-item-title>Inventory History</v-list-item-title>
                    </v-list-item>
                    <!-- <RemoteOrderLink :globalLink="true" :productId="item.id" v-if="item.trackSerialNumber" /> -->
                    <!-- <v-list-item v-if="item.trackInventory && !item.isParent" @click="openSync(item)">
                      <v-list-item-title>Sync Balance</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <v-card color="#F3F8FC" text class="elevation-0" v-if="item.trackSerialNumber">
                <v-card-text class="title">
                  <PurchaseDetailList :ProductId="item.id" status="available"></PurchaseDetailList>
                </v-card-text>
              </v-card>
              <v-card v-else color="#F3F8FC" flat>
                <v-card-text class="title">
                  <PhoneList :ProductId="item.id"></PhoneList>
                </v-card-text>
              </v-card>
            </td>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
            results.</v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="syncDialog" width="500">
      <v-card v-if="selectedProduct">
        <v-card-title>Sync Product</v-card-title>
        <v-container grid-list-lg>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                <span>Name:</span>
                <span class="ml-2">{{ selectedProduct.id }} - {{ selectedProduct.name }}</span>
              </v-flex>
              <v-flex xs12>
                <span>Opening Balance:</span>
                <span class="ml-2">{{ selectedProduct.initBalance }}</span>
              </v-flex>
              <v-flex xs12>
                <span>Current Balance:</span>
                <span class="ml-2">{{ selectedProduct.balance }}</span>
              </v-flex>
              <v-flex xs12>
                <span>Total Purchase:</span>
                <span class="ml-2">{{ selectedProduct.totalPurchase }}</span>
              </v-flex>
              <v-flex xs12>
                <span>Restock:</span>
                <span class="ml-2">{{ selectedProduct.restock }}</span>
              </v-flex>

              <v-flex xs12>
                <span>Total Sold:</span>
                <span class="ml-2">{{ selectedProduct.totalSold }}</span>
              </v-flex>
              <v-flex xs12>
                <span>Mark Sold:</span>
                <span class="ml-2">{{ selectedProduct.markSold }}</span>
              </v-flex>

              <v-flex xs12>
                <span>Serial Balance:</span>
                <span class="ml-2">{{ selectedProduct.serialValue }}</span>
              </v-flex>

              <v-flex xs12>
                <span>Stock Adjustment:</span>
                <span class="ml-2">{{ selectedProduct.stockAdjustment }}</span>
                <!-- <v-btn small class="ml-1" color="success" @click="saveAdj">Save Adjustment</v-btn> -->
              </v-flex>

              <v-flex xs12>
                <span>Real Balance:</span>
                <span class="ml-2">{{ selectedProduct.realBalance }}</span>
              </v-flex>

              <v-flex xs12>
                <span>Adj Should be:</span>
                <span class="ml-2">{{ selectedProduct.shouldBeAdj }}</span>
              </v-flex>
              <v-flex xs12 class="mt-5" v-if="selectedProduct.balance !== selectedProduct.realBalance">
                <v-layout row wrap>
                  <!-- <v-flex xs6>
                    <v-btn color="success" @click="updateBalance">Update Balance</v-btn>
                  </v-flex> -->
                  <v-flex xs6 v-if="selectedProduct.shouldBeAdj !== 0">
                    <v-btn color="blue" @click="fixAdjustment">Fix Adjustment</v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>


          </v-card-text>
        </v-container>

      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="500">
      <v-card v-if="selectedProduct">
        <v-toolbar dense class="mb-3" flat color="grey lighten-3">
          <v-toolbar-title class="subtitle-1">{{ selectedProduct.localId }} | {{ selectedProduct.name
            }}</v-toolbar-title>
          <v-spacer> </v-spacer>

          <v-btn small icon @click.stop.prevent="dialog = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-card-title primary-title>
          <v-layout row class="pa-3">
            <v-flex> Avg Cost </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink v-if="selectedProduct">
              {{ selectedProduct.localId }}
            </v-flex>
          </v-layout>
        </v-card-title> -->
        <v-card-text>
          <v-card dark flat>
            <v-card-text>
              <div class="text-center white--text font-weight-thin">
                <v-progress-circular indeterminate :size="70" v-if="!selectedProduct" :width="7" color="white">
                </v-progress-circular>
                <div v-else>
                  <v-col class="text-h5">
                    <!-- <v-col>
                      <v-row>
                        <v-col sm="6">
                          <div>Stock</div>
                          <div>{{ selectedProduct.totalQty }}</div>
                        </v-col>

                        <v-col sm="6">
                          <div>Cost</div>
                          <div>{{ selectedProduct.totalCost | currency }}</div>
                        </v-col>
                      </v-row>
                    </v-col> -->
                    <!-- <v-divider></v-divider> -->
                    <v-col class="text-h1">
                      {{ selectedProduct.avgCost | currency }}
                    </v-col>
                  </v-col>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <div class="caption text-right">{{ selectedProduct.id }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="historyDialog" width="500">
      <v-card>
        <ModelTitle title="Select Date" @close="closeHistoryDialog()" />

        <v-container grid-list-lg>
          <!-- <VueCtkDateTimePicker
            v-model="dateTime"
            inline
            range
            :onlyDate="true"
            formatted="MM/DD/YYYY"
          /> -->
          <DateTimePicker defaultRange="thisMonth" :range="true" :hideTime="true" v-model="dateTime" />
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="createReport()">
            Create Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import productService from "../service";
import PurchaseDetailList from "@/modules/Inventory/Purchase/components/PurchaseDetailList";
import ImageIcon from "@/components/Image/ImageIcon.vue";
import purchaseService from "@/modules/Inventory/Purchase/service.js";
import { mapGetters } from "vuex";
import GlobalFunction from "@/mixins/GlobalFunction";
import PhoneList from "@/modules/Product/components/PhoneList";
import departmentService from "@/modules/Product/Department/service.js";
import brandService from "@/modules/Product/Brand/service.js";
import categroyService from "@/modules/Product/Category/service.js";
import _ from 'lodash';

const DateTimePicker = () => import("@/components/DateTimePicker");

const Create = () => import("./Create");
const Edit = () => import("./Edit");
// const RemoteOrderLink = () => import("@/components/RemoteOrderLink");


export default {
  name: "product-list",
  data() {
    return {
      departmentList: [],
      selectedDepartment: null,
      brandList: [],
      stockFilter: [],
      categoryList: [],
      selectedCategory: null,
      selectedBrand: null,
      toggle_exclusive: 2,
      toggle_multiple: [1, 2, 3], // temp
      historyDialog: false,
      dateTime: {
        start: this.$moment()
          .startOf("month")
          .toISOString(),
        end: this.$moment()
          .endOf("month")
          .toISOString(),
      },
      openNewTab: [],
      id: 0,
      dialog: false,
      expanded: [],
      singleExpand: true,
      downloadedProductCount: 5000,
      downloadLoading: false,
      hideZeroProduct: false,
      createDialog: false,
      editDialog: false,
      downloadedProducts: [],
      selectedProduct: null,
      syncDialog: false,
      fields: {
        ID: 'localId',
        UPC: 'upc',
        ImageUrl: 'imageUrl',
        Name: 'name',
        Category: 'Category.name',
        Department: 'Department.name',
        Brand: 'Brand.name',
        Stock: 'balance',
        Price: 'price',
        AvgCost: 'avgCost',
        Cost: 'cost'
      },
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "ID",
          value: "localId",
          sortable: true,
        },
        {
          text: this.$t("labels.name"),
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: 'Department',
          align: "left",
          value: "department.name",
          sortable: true,
        },

        {
          text: this.$t("labels.stock"),
          value: "balance",
          align: "center",
          sortable: true,
        },

      ],
      products: [],
      search: "",
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    this.headers.push(
      {
        text: "Price",
        value: "price",
        align: "right",
        sortable: true,
      }
    )

    if (this.currentBusiness.metadata && this.currentBusiness.metadata.showAvgCostOnProduct) {
      this.headers.push({
        text: "Avg Cost",
        value: "avgCost",
        align: "center",
        sortable: true,
      },)
    } else {
      this.headers.push({
        text: "Cost",
        value: "cost",
        align: "center",
        sortable: true,
      })
    }

    this.headers.push(
      {
        text: this.$t("labels.category"),
        value: "Category.name",
        align: "left",
        sortable: true,
      },
      {
        text: "",
        value: "action",
        sortable: false,
      })
    this.getDepartment()
    this.getBrand()
    this.getCategory()
    this.getList();
    if (this.$route.query && this.$route.query.search) {
      this.search = this.$route.query.search;
    }
    document.title = "smplsale - Product";
  },
  components: {
    Create,
    ImageIcon,
    DateTimePicker,
    // RemoteOrderLink,
    Edit,
    PurchaseDetailList,
    PhoneList,
  },
  mixins: [GlobalFunction],
  watch: {
    stockFilter() {
      this.hideZeroProduct = !this.hideZeroProduct
    },
    hideZeroProduct() {
      this.getList();
    },
    // search(val) {
    //   if (!val) {
    //     this.search = "";
    //     this.getList()
    //   } else {
    //     this.getList()
    //   }
    // },
    search: _.debounce(function (val) {
      // this.resetProduct()
      if (this.search === null) {
        // this.search = "";
        this.getList();
      } else if (this.search.length > 2 || this.search.length === 0) {

        this.getList();

      }

    }, 300),
  },
  computed: {
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
  },
  methods: {
    getDepartment() {
      return departmentService
        .getAll()
        .then((response) => {
          // console.log('response', response)
          // this.loading = false;
          this.departmentList = response.data;
          return response;
        });
    },
    getBrand() {
      return brandService
        .getAll()
        .then((response) => {
          // console.log('response', response)
          // this.loading = false;
          this.brandList = response.data;
          return response;
        });
    },
    getCategory() {
      return categroyService
        .getAll()
        .then((response) => {
          // console.log('response', response)
          // this.loading = false;
          this.categoryList = response.data;
          return response;
        });
    },
    // saveAdj() {
    //   this.selectedProduct.stockAdjustment
    //   productService
    //     .update(row.id, row)
    //     .then((response) => {
    //       console.log("response", response);
    //     });
    // },
    updateBalanceProduct(product) {
      // console.log('i call this')
      productService.updateBalance(product.id).then(result => {
        // console.log('update balance result', result)
        this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    fix() {
      // let newBoxPrice = parseFloat(row.unitPrice) * parseFloat(row.unitCountInBox)
      // console.log('before', parseFloat(row.boxPrice), parseFloat(row.unitPrice), parseFloat(row.price), parseFloat(row.boxPrice) > parseFloat(row.price))

      return productService
        .fix()
        .then((result) => {
          if (result.status) {
            console.log('result', result)
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Product is updated",
              });
            // this.getList();
          }
        })
        .catch((err) => {
          this.loading = false;
          // console.log("err", err);
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "error",
              title: err.data.message,
            });
        });



      // if (!row.isParent && row.unitCountInBox > 0) {
      //   if (!row.fix) {
      //     console.log('in iter')
      //     let newCost = 0
      //     let newPrice = parseFloat(row.price) * parseFloat(row.unitCountInBox)
      //     row.price = newPrice
      //     row.fix = true
      //     if (row.cost) {
      //       newCost = parseFloat(row.cost) * parseFloat(row.unitCountInBox)

      //       row.cost = newCost
      //     }


      //     console.log('before', newPrice, newCost, row.fix)

      //   }
      // }




    },
    async fetchProductsForDownload() {
      this.downloadLoading = true;
      this.search = ''
      this.downloadedProductCount = 5000
      await this.getList()
      this.downloadLoading = false
      return this.downloadedProducts;
    },
    costGreater(item) {
      if (item.isParent)
        return false
      if (this.currentBusiness.metadata && this.currentBusiness.metadata.showAvgCostOnProduct) {
        if (item.avgCost > item.price)
          return true
        return false
      }
      if (item.cost > item.price)
        return true
      return false
    },
    fixAdjustment() {
      let obj = {
        adjustmentQuantity: this.selectedProduct.shouldBeAdj,
        reason: 'Manual Adjustment',
        adjustmentType: 'Decrease',
        ProductId: this.selectedProduct.id
      }
      if (this.selectedProduct.shouldBeAdj > 0) {
        obj.adjustmentType = 'Increase'
      }

      console.log('obj', obj)

      return productService.stockAdjustment
        .create(obj)
        .then((result) => {
          if (result.status) {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Product is updated",
              });
            // this.empty();

            this.getList()
            this.updateStock(this.selectedProduct)
            this.loading = false;
            this.syncDialog = false
            // this.$emit("submit");
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log("err", err);
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "error",
              title: err.data.message,
            });
        });
    },
    updateBalance() {
      // let initBalance = this.selectedProduct.realBalance + this.selectedProduct
      return productService
        .update(this.selectedProduct.id, { initBalance: this.selectedProduct.realBalance })
        .then((result) => {
          if (result.status) {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Product is updated",
              });
            // this.empty();

            this.getList()
            this.loading = false;
            this.syncDialog = false
            // this.$emit("submit");
          }
        })
        .catch((err) => {
          this.loading = false;
          // console.log("err", err);
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "error",
              title: err.data.message,
            });
        });
    },
    viewAvgCost(item) {
      productService.getAvgCost(item.id).then((response) => {
        console.log('response viewAvgCost', response)
        this.selectedProduct = response.data.product;
        item.avgCost = response.data.avgCost;
        this.selectedProduct.totalCost = response.data.totalCost;
        this.selectedProduct.totalQty = response.data.totalQty;
      });

      // console.log("priess view avt gost", item);
      this.dialog = true;
    },
    openHistory(item) {
      // const routeData = this.$router.resolve({
      //   path: `/global/statement/${hashId}/view?start=${this.dateTime.start}&end=${this.dateTime.end}`,
      // });
      // window.open(routeData.href, "newwindow", "width=850, height=1100");
      // this.dialog = false;
      this.historyDialog = true
      this.selectedProduct = item
      // let routeData = this.$router.resolve({
      //   path: `/reportView/product/history/${item.id}`,
      // });
      // window.open(routeData.href, "_blank");
    },
    closeHistoryDialog() {
      // console.log('call close')
      this.historyDialog = false
    },
    createReport() {
      this.closeHistoryDialog()
      let routeData = this.$router.resolve({
        path: `/reportView/product/history/${this.selectedProduct.id}?start=${this.dateTime.start}&end=${this.dateTime.end}`,
      });
      window.open(routeData.href, "_blank");
    },
    openSync(item) {
      productService.getSyncInfo(item.id).then((response) => {
        console.log('response', response)
        this.selectedProduct = response.data;
        // this.selectedProduct.shouldBeAdj = parseFloat(this.selectedProduct.balance) - parseFloat(this.selectedProduct.realBalance)
        // item.avgCost = response.data.avgCost;
        // this.selectedProduct.totalPurchase = response.data.totalPurchase;
        // this.selectedProduct.totalSold = response.data.totalSold;
      });
      this.syncDialog = !this.syncDialog
    },
    updateAllList() {
      let index = 0
      this.products.map(row => {

        if (row.trackSerialNumber) {
          index +=
            this.updateStock(row)
          console.log('index', index)
        }
      })
    },
    updateStock(row) {
      if (row.trackSerialNumber) {
        const where = {
          status: "available",
          ProductId: row.id,
        };
        return purchaseService.getSerialVault(where).then(async (response) => {
          const stock = response.data;
          console.log('stock', stock.length)
          row.balance = stock.length
          // this.Product.balance = this.stocks.length;
          // console.log("product ", this.Product);
          productService
            .update(row.id, row)
            .then((response) => {
              console.log("response", response);
            });
        });
      } else {
        console.log('stock row', row)
      }
    },
    inventoryAdj(item) {
      if (this.openNewTab.length !== 0) {
        let routeData = this.$router.resolve({ path: `/product/inventoryAdj/${item.id}` });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ path: `/product/inventoryAdj/${item.id}` });
      }
      // this.$router.push({ path: `/product/inventoryAdj/${item.id}` });
    },
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      this.editDialog = false;
      this.createDialog = false;
      const where = {
        search: this.search,
        limit: this.downloadedProductCount,
        hideZero: this.hideZeroProduct
      }
      if (this.selectedCategory) {
        where.CategoryId = this.selectedCategory.id
      }
      if (this.selectedBrand) {
        where.BrandId = this.selectedBrand.id
      }
      if (this.selectedDepartment) {
        where.DepartmentId = this.selectedDepartment.id
      }

      // console.log('where', where)
      return productService.getAll(where).then((response) => {
        // console.log("response product list:", response);
        this.loading = false;
        const product = response.data;
        this.downloadedProducts = response.data

        this.products = [];
        if (this.hideZeroProduct) {
          product.map((row) => {
            if (row.balance > 0) this.products.push(row);
          });
        } else {
          this.products = product;
        }

        if (this.currentBusiness.metadata && this.currentBusiness.metadata.dynamicPrice) {
          this.products.map(row => {
            row.dynamicPrice = this.getDynamicPrice(row, this.currentBusiness.metadata.dynamicPriceList)
          })
        }
        return response;
      });
    },
    editProduct(product) {
      this.selectedProduct = product;
      this.id = product.id;
      this.editDialog = !this.editDialog;
    },
    deleteProduct(product) {
      this.updateBalanceProduct(product)
      if (product.balance > 0) {
        this.$swal({
          text: 'Delete',
          icon: 'info',
          html: `<b>${product.name}</b> have <b style="color: red;">${product.balance}</b>  items in stock remove them before you delete the product.`
        })
        return false
      }

      if (this.currentBusiness.metadata && this.currentBusiness.metadata.deletePrecaution) {
        this.$swal.fire({
          title: "Are you sure?",
          html: `You won't be able to revert this.</br> <div style="color:red;">Type <span style="font-weight: bold">delete</span> in order remove this</div>`,
          icon: "question",
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        }).then((result) => {
          console.log('result', result)
          if (result.value && result.value === 'delete') {
            this.loadingDialog = true;
            productService
              .delete(product.id)
              .then((response) => {
                // console.log("response delete", response);
                this.loadingDialog = false;
                if (response.data) {
                  this.$swal
                    .mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                    })
                    .fire({
                      icon: "success",
                      title: "Product has been deleted.",
                    });
                  // this.$swal("Deleted!", "Invoice has been deleted.", "success");
                  this.getList();
                  this.$events.emit("customerProfileEvent");
                  this.$events.emit("CustomerMiniList");
                }
              })
              .catch((error) => {
                this.loadingDialog = false;
                this.$swal({
                  title: "Delete Invoice",
                  text: error.data.message,
                  type: "error",
                });
              });
          } else if (result.dismiss === 'cancel') {

          } else {
            this.$swal("Delete", 'Type "delete" to delete item', "error");
          }
        })
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            productService.delete(product.id).then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Product has been deleted.", "success");
                this.getList();
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.isParent {
  background: #E8F5E9;
}

.hightlightRed {
  background: #FFCDD2;
}
</style>
